import React from "react";
import "./Loader.scss";

import { Spinner } from "@chakra-ui/react";
const Loading= () => {
  return (
    <div className="loading">
      <div className="logo">
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='orange.500'
        size='xl'
        />
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loading;
