import React from 'react';

import {ChakraProvider} from '@chakra-ui/react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from './components/Header';
import DataVisualization from './components/DataVisualization';
import Footer from './components/Footer';
const App = () => {
  return (
<ChakraProvider>
<Router>
  <Header />
  <main >
  <Routes>
  <Route
    path="*"
    element={<DataVisualization />}
   />
  </Routes>
  </main>
  <Footer/>
</Router>
</ChakraProvider>
  );
};

export default App;
