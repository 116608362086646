import React from 'react';
import {  Link  , Center, Alert, AlertIcon, Stack, Heading , Text, CardHeader, CardBody, CardFooter, Card} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import './DataVisualization.scss'

import DataReader from './DataReader';



const DataVisualization = () => {

  return (
<Center marginTop={40} padding={2}>
<Card  minW='70%' width={{ base: '100%', sm: '90%', md: '80%' }}  alignSelf="center">
  <CardHeader textAlign="center" alignItems={"center"} alignSelf={"center"} alignContent={"center"} >
  <Heading size='lg' padding={5}>Data Visualization</Heading>
  <Heading size="md" paddingBottom={10}>Data visualization demo using{" "}<Link href="https://recharts.org/en-US/" isExternal>Recharts<ExternalLinkIcon mx='2px' />
    </Link></Heading>
    <Text>Data visualization plays a crucial role in understanding complex information and deriving valuable insights.
       By visually representing data through charts, graphs, and interactive visualizations, it becomes easier to identify patterns, trends, and relationships within the data. Visualizations enable us to communicate information effectively, making it accessible and understandable to a wider audience. They facilitate decision-making processes by presenting data in a concise and intuitive manner, empowering users to make informed decisions based on data-driven insights. Data visualization enhances comprehension, promotes data exploration, and helps uncover valuable knowledge hidden within the vast sea of data.</Text>
    <Alert  alignItems={"center"} alignSelf={"center"} alignContent={"center"} status='warning'  maxWidth="100%" >
    <AlertIcon />Maximum overall file size: 1MB. Maximum rows: 100. Maximum columns: 20
  </Alert>
  </CardHeader>
  <CardBody>
    <Stack alignContent={"center"} alignItems={"center"}>
    <DataReader />
    </Stack>
  </CardBody>
  <CardFooter>
  </CardFooter>
</Card>
</Center >
  );
};

export default DataVisualization;
