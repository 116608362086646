import { useTable } from 'react-table';
import React, {  useMemo } from 'react';

const Table = ({tableData}) => {
    const header = useMemo(() => {
      if (tableData.length > 0) {
        const firstRow = tableData[0];
        return Object.keys(firstRow).map((key) => ({
          Header: firstRow[key],
          accessor: key,
        }));
      }
      return [];
    }, [tableData]);

    const data = useMemo(() => tableData.slice(1), [tableData]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns: header, data });

    if (tableData.length === 0) {
      return <div>No data to display</div>;
    }

    return (
      <div style={{ width: '100%', height: '400px', overflow: 'auto' }}>
        <table
          {...getTableProps()}
          style={{
            border: 'solid 1px black',
            width: '100%',
            tableLayout: 'fixed',
          }}
        >
          <colgroup>
            {header.map((column) => (
              <col
                key={column.Header}
                style={{ width: 'auto', minWidth: '100px' }}
              />
            ))}
          </colgroup>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup?.getHeaderGroupProps()} style={{ position: 'sticky', top: 0 }}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: 'solid 3px tomato',
                      background: 'aliceblue',
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          border: 'solid 1px gray',
                          background: 'white',
                          overflowWrap: 'break-word',
                          wordWrap: 'break-word',
                          wordBreak: 'break-word',
                        }}
                      >
                        <span style={{ display: 'inline-block', maxWidth: '100%' }}>
                          {cell.render('Cell')}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };


  export default Table;