import React, { useState, useCallback } from 'react';
import DragDropFile from './DragDropFile';
import XLSX  from "xlsx";
import CharComponent from './ChartComponent';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Select,
  HStack,
  FormLabel,
  SimpleGrid
} from '@chakra-ui/react'
import Loader from './Loader';
import Table from './Table'

import  { useCurrentPng } from 'recharts-to-png';
import {saveAs} from "file-saver"

const STATIC_DATA = [
  [
    "Driver",
    "Distance (in miles)",
    "Duration of delivery (minutes)"
  ],
  [
    "Liam",
    1.6,
    22
  ],
  [
    "Emma",
    12,
    39
  ],
  [
    "Noah",
    6.1,
    38
  ],
  [
    "Olivia",
    4.4,
    26
  ],
  [
    "Ava",
    6.3,
    39
  ],
  [
    "Isabella",
    4.9,
    27
  ],
  [
    "Sophia",
    0.9,
    24
  ],
  [
    "Mia",
    2.9,
    28
  ],
  [
    "Charlotte",
    1.1,
    30
  ],
  [
    "Amelia",
    15,
    29
  ],
  [
    "Harper",
    0.8,
    22
  ],
  [
    "Evelyn",
    4.5,
    30
  ],
  [
    "Abigail",
    1.8,
    30
  ],
  [
    "Emily",
    0.6,
    20
  ],
  [
    "Elizabeth",
    1.8,
    36
  ],
  [
    "Mila",
    7.5,
    21
  ],
  [
    "Ella",
    5.3,
    47
  ],
  [
    "Avery",
    2.5,
    25
  ],
  [
    "Scarlett",
    2.6,
    48
  ],
  [
    "Sofia",
    8.4,
    26
  ],
  [
    "Camila",
    7.2,
    49
  ],
  [
    "Aria",
    1.5,
    27
  ],
  [
    "Penelope",
    0.9,
    23
  ],
  [
    "Layla",
    6.6,
    28
  ],
  [
    "Chloe",
    5.6,
    24
  ],
  [
    "Victoria",
    5.2,
    29
  ],
  [
    "Madison",
    13.9,
    25
  ],
  [
    "Lily",
    5.5,
    30
  ],
  [
    "Grace",
    0.7,
    21
  ],
  [
    "Zoey",
    8.7,
    31
  ],
  [
    "Hannah",
    4.9,
    42
  ],
  [
    "Lillian",
    15,
    52
  ],
  [
    "Natalie",
    3.6,
    43
  ],
  [
    "Luna",
    5.1,
    53
  ],
  [
    "Savannah",
    10.3,
    50
  ],
  [
    "Audrey",
    7.1,
    54
  ],
  [
    "Brooklyn",
    6.8,
    51
  ],
  [
    "Leah",
    4.6,
    66
  ],
  [
    "Stella",
    8.1,
    52
  ],
  [
    "Ellie",
    2.4,
    56
  ],
  [
    "Skylar",
    2.1,
    53
  ],
  [
    "Violet",
    7.5,
    53
  ],
  [
    "Claire",
    5,
    62
  ],
  [
    "Aurora",
    2.7,
    54
  ],
  [
    "Lucy",
    14.6,
    55
  ],
  [
    "Paisley",
    1.1,
    26
  ],
  [
    "Everly",
    9.6,
    56
  ],
  [
    "Anna",
    1.9,
    27
  ],
  [
    "Caroline",
    3.4,
    57
  ],
  [
    "Nova",
    4.9,
    28
  ]
]




const STATIC_HEADER_ROW = STATIC_DATA[0];
const STATIC_CHART_DATA = STATIC_DATA.slice(1).map((row) => {
  const dataObj = {};
  for (let i = 0; i < STATIC_HEADER_ROW.length; i++) {
    dataObj[STATIC_HEADER_ROW[i]] = row[i];
  }
  return dataObj;
});

const DataReader = () => {
  const [tableData, setTableData] = useState(STATIC_DATA);
  const [chartData, setChartData] = useState(STATIC_CHART_DATA);
  const [fileName, setFileName] = useState("Sample Data");
  const [chartType, setChartType] = useState('bar');
  const [theHeaders, setTheHeaders] = useState(STATIC_HEADER_ROW);
  const [yOptions, setYOptions] = useState(STATIC_HEADER_ROW.slice(1));
  const [xAxisKey, setXAxisKey] = useState(STATIC_HEADER_ROW[0]);
  const [yAxisKeys, setYAxisKeys] = useState(STATIC_HEADER_ROW.slice(1));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [getPng, theCurrentPng] = useCurrentPng();
  const closeModal = ()=>{
    setModalMessage("");
    setIsModalOpen(false);
  }


  const handleDownload = useCallback(async () => {
    const png = await getPng();
    if (png) {
      saveAs(png, `${fileName}.png`);
    }
  }, [getPng, fileName]);

  const readFileAsync = (file) => {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try{
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          resolve(jsonData);
  
        }catch(e){
          console.error("Cannot read file");
          reject("Cannot read file");
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };


  return (
    <Stack alignContent={"center"} alignItems={"center"} width={"100%"}>
    {isLoading &&  <Loader/>}
    <DragDropFile handleFiles={async (file) => {
      try{
        const max_size = 1024;
        if (file.size/1024 > max_size){
        setModalMessage("Max file size is 1MB");
         setIsModalOpen(true);

          return;
        }
        setIsLoading(true);
        const jsonData = await readFileAsync(file);
        const maxRows = 100; 
        const maxColumns = 20; 
        const headerRow = jsonData[0];
        const numRows = jsonData.length - 1; 
        const numColumns = headerRow.length;
        if (numRows > maxRows || numColumns > maxColumns) {
          setModalMessage(`Number of rows (${numRows}) or columns (${numColumns}) exceeds the maximum allowed limit of 100 and 20.`);
          setIsModalOpen(true)
          setIsLoading(false);
          return;
        }
        const theChartData = jsonData.slice(1).map((row) => {
          const dataObj = {};
          for (let i = 0; i < headerRow.length; i++) {
            dataObj[headerRow[i]] = row[i];
          }
          return dataObj;
        });
        setTheHeaders(headerRow)
        setTableData(jsonData)
        setXAxisKey(headerRow[0]);
        setYOptions(headerRow.slice(1))
        setYAxisKeys(headerRow.slice(1,3));
        setChartData(theChartData);

         if (file) {
          setFileName(file.name);
        } else {
          setFileName('');
        }
          }catch(e){
            console.error("Cannot read file.");
          }
          setIsLoading(false);
    }}/>
    {fileName && <Text fontSize={24}>{fileName}</Text>}
    <Table tableData={tableData} />
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
    <Stack>
    <FormLabel htmlFor='chart-type'>Chart:</FormLabel>
    <Select
     id="chart-type"
      maxWidth={200}
      borderColor='tomato'
      value={chartType}
      onChange={(e) => setChartType(e.target.value)}
    >
        <option value="bar">Bar Chart</option>
        <option value="line">Line Chart</option>
        <option value="area">Area Chart</option>
        <option value="scatter">Scatter Chart</option>
    </Select> 
    </Stack>
    <Stack>
      <FormLabel htmlFor='x-axis'>X Axis:</FormLabel>
      <Select
      id="x-axis"
      maxWidth={200}
      borderColor='tomato'
      marginRight={10}
      value={xAxisKey}
      onChange={(e) => {
        const val = e.target.value;
        const newY = yAxisKeys.filter(y=> y!==val)
        newY.push(xAxisKey)
        setYAxisKeys(newY)
        const newYOps = yOptions.filter(y=> y!==val)
        newYOps.push(xAxisKey)
        setYOptions(newYOps)
        setXAxisKey(val)

      }}
    >
      {theHeaders.map(h=>  <option  key={h}value={h}>{h}</option> )}
    </Select>
    </Stack>
    <Stack>
    <FormLabel htmlFor='y-axis'>Y Axis:</FormLabel>
    <select
      width={500}
      multiple={true}
      size={8}
      value={yAxisKeys}
      onChange={(e) => {
        const selectedOpts = [...e.target.selectedOptions].map((o) => o.value);
				setYAxisKeys([...selectedOpts]);
      }}
    >
      {yOptions.map(y=>  <option  key={y}value={y}>{y}</option> )}
    </select>
    </Stack>


    </SimpleGrid>
    {chartData?.length > 0 && <CharComponent theRef={theCurrentPng.ref} data={chartData} chartType={chartType} xAxisKey={xAxisKey} yAxisKeys={yAxisKeys} />}
    <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ooops!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalMessage}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='orange' mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack gap={10}>
      <Button onClick={handleDownload} colorScheme="orange" mt={4}>
      {theCurrentPng.isLoading ? 'Downloading...' : 'Download Chart'}
      </Button>
      <Button onClick={()=>{
        setTableData(STATIC_DATA);
        setXAxisKey(STATIC_HEADER_ROW[0]);
        setYAxisKeys(STATIC_HEADER_ROW.slice(1));
        setYOptions(STATIC_HEADER_ROW.slice(1))
        setChartData(STATIC_CHART_DATA);
        setChartType("bar");
        setFileName("Sample Data");
        setTheHeaders(STATIC_HEADER_ROW)
      }} colorScheme="orange" mt={4}>
        Reset
      </Button>
      </HStack>
    </Stack >
  );
};

export default DataReader;
