import React, {useState} from "react";
import {Box, Flex, Button, Text} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = ()=> setIsModalOpen(false);
  return (
    <Box backgroundColor="#18181b" marginTop={100}>
      <footer>
        <Flex
          margin="0 auto"
          px={12}
          color="white"
          justifyContent="center"
          alignItems="center"
          maxWidth="1024px"
          height={16}
        >
          <p>incrementtum • © 2023</p>
          <Button marginLeft={20} colorScheme='orange' mr={3} onClick={()=> setIsModalOpen(true)}>
              Privacy
            </Button>
        </Flex>
      </footer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <Text>
           We use third-party 3rd party cookies from Google to collect anonymous traffic data. Information collected in this way will not contain personally identifiable information, but may contain demographic data such as location and gender.
           </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Footer;
