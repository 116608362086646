import React from 'react';


    import {
        BarChart,
        Bar,
        LineChart,
        Line,
        AreaChart,
        Area,
        ScatterChart,
        Scatter,
        XAxis,
        YAxis,
        CartesianGrid,
        Tooltip,
        Legend,
        ResponsiveContainer,
      } from 'recharts';
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
      const CharComponent = ({ theRef, data, chartType, xAxisKey, yAxisKeys }) => {
        const getChartComponent = () => {
          switch (chartType) {
            case 'bar':
              return (
                <BarChart ref={theRef}  width={800} height={400} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis  dataKey={xAxisKey}  angle={-45} textAnchor="end" />
                  <YAxis />
                  <Tooltip />
                  <Legend wrapperStyle={{top: 0, left: 25}} />
                  {yAxisKeys.map((key, index) => (
                    <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]}/>
                  ))}
                </BarChart>
              );
            case 'line':
              return (
                <LineChart ref={theRef}  width={600} height={400} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xAxisKey} angle={-45} textAnchor="end"  />
                  <YAxis />
                  <Tooltip />
                  <Legend wrapperStyle={{top: 0, left: 25}} />
                  {yAxisKeys.map((key, index) => (
                    <Line key={key} type="monotone" dataKey={key} stroke={COLORS[index % COLORS.length]} />
                  ))}
                </LineChart>
              );
            case 'area':
              return (
                <AreaChart ref={theRef}   width={600} height={400} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xAxisKey} angle={-45} textAnchor="end"  />
                  <YAxis />
                  <Tooltip />
                  <Legend wrapperStyle={{top: 0, left: 25}}  />
                  {yAxisKeys.map((key, index) => (
                    <Area key={key} type="monotone" dataKey={key} fill={COLORS[index % COLORS.length]} />
                  ))}
                </AreaChart>
              );
            case 'scatter':
              return (
                <ScatterChart ref={theRef}  width={600} height={400} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xAxisKey}  angle={-45} textAnchor="end" />
                  <YAxis />
                  <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                  <Legend wrapperStyle={{top: 0, left: 25}}  />
                  {yAxisKeys.map((key, index) => (
                    <Scatter key={key} dataKey={key} fill={COLORS[index % COLORS.length]}/>
                  ))}
                </ScatterChart>
              );
            default:
              return null;
          }
        };
      
        return <ResponsiveContainer width='100%' aspect={3.0/2.0}>{getChartComponent()}</ResponsiveContainer>;
      };




export default CharComponent;


