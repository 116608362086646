import { Box, HStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'




const Header = () => {
  const [transform, setTransform] = useState(0)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset

    if (currentScrollPos > prevScrollPos) {
      setTransform('translateY(-200px)')
    } else {
      setTransform('translateY(0px)')
    }

    setPrevScrollPos(currentScrollPos)
  }


  return (
    <Box
      position='fixed'
      top={0}
      left={0}
      right={0}
      transform={transform}
      transitionProperty='transform'
      transitionDuration='.5s'
      transitionTimingFunction='ease-in-out'
      backgroundColor='#18181b'
      zIndex={10}
    >
      <Box color='white' maxWidth='1280px' margin='0 auto'>
        <HStack
          px={16}
          py={4}
          justifyContent='space-between'
          alignItems='center'
        >

          <nav>
            <HStack spacing={8}>
              <p>By johnguaz Powered by {" "}
              <a style = {{color: "#8ed1fc"}}href='https://incrementtum.com' target='__blank'  >
                 incrementtum
              </a>
              </p>
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  )
}
export default Header
